import dayjs from "dayjs";

export default {

  trDate (d, withTime = true) {
    const format = withTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
    return dayjs(d).format(format);
  },

  mysqlDate (d, withTime = true) {
    const format = withTime ? "YYYY-MM-DD HH:mm:ss" :  "YYYY-MM-DD";
    return dayjs(d).format(format);
  }

}