<template>
  <form @submit.prevent="submit">
    <Card class="w-full md:w-96 mt-16 m-auto">
      <template #title>
        <h1>Giriş</h1>
      </template>  
            
      <template #content>
        <form-row>
          <form-item label="Kullanıcı">
            <InputText type="text" class="w-full" v-model="login.email" />
          </form-item>
      
          <form-item label="Şifre" class="mt-4">
            <InputText type="password" class="w-full" v-model="login.password" />
          </form-item>

          <div class="mt-4">
            <div class="flex">
              <Checkbox v-model="login.rememberMe" :binary="true" />
              <span class="pl-1">Beni hatırla</span>
            </div>
          </div>
        </form-row>
      
        <Button type="submit" label="Gir" icon="pi pi-user" class="w-full"></Button>
              
        <Message class="mb-0" v-if="error" severity="error" :closable="false">{{error}}</Message>
      </template>
    </Card>
  </form>
</template>

<script>
export default {
  emits: ["admin"],

  data() {
    return {
      error: null,
      login: {
        rememberMe: false,
        email: "",
        password: "",
      }
    };
  },

  methods: {
    async submit () {
      this.error = null;
      const ret = await this.$post("/admin/login", this.login);
      if (ret.err) return this.error = ret.err;
      if (ret.admin) {
        this.$emit('admin', ret.admin);
      }
    }
  }
}
</script>