<template>
  <router-link :to="{name: 'reservation', params: {id}}">
    <Button icon="pi pi-shopping-cart" rounded outlined
      v-tooltip="'Rezervasyonu gör'" />
  </router-link>
</template>

<script>
export default {
  props: ["id"],
}
</script>