<template>
  <div class="mb-6">
    <slot></slot>

  </div>

</template>

<script>
export default {

}
</script>