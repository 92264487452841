const qs = require("qs");
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

//const base = process.env.VUE_APP_API_URL;
//const base = "http://localhost:8082";
const base = "/admin";

export default {

  async post (uri, body) {

    const response = await fetch(base + uri, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    });

    return await response.json();
  },

  async get (uri, params) {
    let q = qs.stringify(params);
    if (q) q = "?" + q;
    const response = await fetch(base + uri + q , {
      method: 'GET',
      headers,
    });

    return await response.json();
  },

  async getFile (uri, params, fileName) {
    try {
      let q = qs.stringify(params);
      if (q) q = "?" + q;
      const response = await fetch(base + uri + q , {
        method: 'GET',
        headers,
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = fileName || 'rapor.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      //alert('your file has downloaded!'); // or you know, something with better UX...
    } catch (err) {
      console.log("Malesef ruhu yok: ", err.message);
    }
  },

  /** Download contents as a file
   * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
   */
  /*
  downloadBlob (content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
    pom.remove();
  }
  */

}