<template>
  <Button type="submit" :label="label != undefined ? label : 'Kaydet'"
    icon="pi pi-save" :loading="loading"
    @click="$emit('click')" />
</template>

<script>
export default {
  emits: ["click"],
  props: ["label", "loading"]
}
</script>