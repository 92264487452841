import { createApp, shallowRef } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import dayjs from 'dayjs';
import trLocale from "dayjs/locale/tr"
dayjs.locale(trLocale);

import router from './router';
import ajax from "./lib/ajax.js";
import date from "./lib/date.js";

import "@/assets/main.css";

//import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/themes/saga-green/theme.css"
//import "primevue/resources/themes/nano/theme.css"
//import "primevue/resources/themes/fluent-light/theme.css"
//import "primevue/resources/themes/mdc-light-indigo/theme.css";

import "primevue/resources/primevue.min.css"                 //core css
import 'primeicons/primeicons.css';

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: "https://10f8e44068084d238d05a677104613e3@o335470.ingest.sentry.io/4505206080798720",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router)
  .use(PrimeVue, {
    locale: {
      "startsWith":"Başlangıç",
      "contains":"Barındırır",
      "notContains":"İçinde Barındırmaz",
      "endsWith":"Bitiş",
      "equals":"Eşittir",
      "notEquals":"Eşit Değildir",
      "noFilter":"Filtresiz",
      "lt":"Daha az",
      "lte":"Daha az veya Eşit",
      "gt":"Daha Fazla",
      "gte":"Daha fazla veya Eşit",
      "dateIs":"Tarih",
      "dateIsNot":"Tarih değildir",
      "dateBefore":"Tarihten önce",
      "dateAfter":"Tarihten sonra",
      "custom":"Özel",
      "clear":"Sıfırla",
      "apply":"Uygula",
      "matchAll":"Tümüyle eşleşir",
      "matchAny":"Herhangi birine eşleşir",
      "addRule":"Kural Ekle",
      "removeRule":"Kuralı Sil",
      "accept":"Tamam",
      "reject":"İptal",
      "choose":"Seç",
      "upload":"Yükle",
      "cancel":"Vazgeç",
      "dayNames":["Pazar","Pazartesi","Salı","Çarşamba","Perşembe","Cuma","Cumartesi"],
      "dayNamesShort":["Paz","Pzt","Sal","Çar","Per","Cum","Cmt"],
      "dayNamesMin":["Pz","Pt","Sa","Ça","Pe","Cu","Ct"],
      "monthNames":["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
      "monthNamesShort":["Oca","Şub","Mar","Nis","May","Haz","Tem","Ağu","Eyl","Eki","Kas","Ara"],
      "today":"Bugün",
      "weekHeader":"Hf",
      "firstDayOfWeek":1,
      "dateFormat":"dd.mm.yy",
      "weak":"Zayıf",
      "medium":"Orta",
      "strong":"Güçlü",
      "passwordPrompt":"Şifre Giriniz",
      "emptyFilterMessage":"Kullanılabilir seçenek yok",
      "emptyMessage":"Sonuç bulunamadı",
      "aria": {
            "trueLabel": "Doğru",
            "falseLabel": "Yanlış",
            "nullLabel": "Seçilmedi",
            "pageLabel": "Sayfa",
            "firstPageLabel": "İlk Sayfa",
            "lastPageLabel": "Son Sayfa",
            "nextPageLabel": "Sonraki Sayfa",
            "previousPageLabel": "Önceki Sayfa"
      }
   }
  });
app.config.globalProperties.$get = ajax.get;
app.config.globalProperties.$post = ajax.post;
app.config.globalProperties.$getFile = ajax.getFile;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$date = date;
app.config.globalProperties.$admin = shallowRef(null);

//app's imports:
import formRow from "./components/form-row";
import formItem from "./components/form-item";
import formRowItem from "./components/form-row-item";
import memberFrom from "./components/member-form";
import saveButton from "./components/save-button";
import rezLink from "./components/rez-link";
import reportCommands from "./components/report-commands.vue";

app.component("form-row", formRow);
app.component("form-item", formItem);
app.component("form-row-item", formRowItem);
app.component("member-form", memberFrom);
app.component("save-button", saveButton);
app.component("rez-link", rezLink);
app.component("report-commands", reportCommands);

import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import RadioButton from 'primevue/radiobutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import SplitButton from "primevue/splitbutton";
import Menu from "primevue/menu";
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';

app.component("Button", Button);
app.component("InputSwitch", InputSwitch);
app.component("Calendar", Calendar);
app.component("SelectButton", SelectButton);
app.component("Dropdown", Dropdown);
app.component("Divider", Divider);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("Message", Message);
app.component("Checkbox", Checkbox);
app.component("Dialog", Dialog);
app.component("InputNumber", InputNumber);
app.component("Toast", Toast);
app.use(ToastService);
app.directive("tooltip", Tooltip);
app.component("ConfirmDialog", ConfirmDialog);
app.use(ConfirmationService);
app.component("RadioButton", RadioButton);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("SplitButton", SplitButton);
app.component("Menu", Menu);
app.component("Badge", Badge);
app.directive('badge', BadgeDirective);

app.mount('#app');
