<template>
  <div class="mx-auto print:hidden fixed w-full top-0 z-50" v-if="uiReady && $admin.value">
    <Menubar :model="menu">
      <template #end>
        <div class="flex items-center">
          <form @submit.prevent="search">
            <InputText v-model="searchTerm" placeholder="email veya id" required
              type="text" class="w-auto" :disabled="searchDisabled"
              ref="global-search"  />
            <input type="submit" v-show="false" />
          </form>

          <!-- ar choose -->
          <router-link class="mx-3" title="AR Seçsin" v-tooltip.left="'AR Seçsin'"
            v-if="common && common.arChoose.all" :to="{name: 'ar-choose'}">

            <i v-if="common.arChoose.all" v-badge.danger="common.arChoose.all"
              class="pi pi-bolt p-overlay-badge" style="font-size: 1.4rem" />
          </router-link>

          <!-- unpaid orders -->
          <router-link class="mx-3" title="Ödenmemiş" v-tooltip.left="'Ödenmemiş'"
            v-if="common && common.unpaid.all" :to="{name: 'unpaid'}" >

            <i v-if="common.unpaid.havale" v-badge.danger="common.unpaid.havale"
              class="pi pi-bell p-overlay-badge" style="font-size: 1.4rem" />
            <i v-else v-badge.info="common.unpaid.all"
              class="pi pi-bell p-overlay-badge" style="font-size: 1.4rem" />
            <!-- <Badge value="4" severity="danger"></Badge> -->
          </router-link>

          <Button icon="pi pi-user" aria-haspopup="true" aria-controls="admin-menu"
            @click="$refs['admin-menu'].toggle($event)" text />

          <Menu ref="admin-menu" id="admin-menu" :model="adminMenuItems" :popup="true" />
        </div>
      </template>
    </Menubar>

  </div>

  <div class="container mx-auto mt-20 px-2">
    <router-view v-if="uiReady && $admin.value" />
    <Toast />
    <ConfirmDialog />

    <Login v-if="uiReady && ! $admin.value" @admin="$admin.value = $event" />
  </div>
</template>

<script>
import Menubar from 'primevue/menubar';
import Login from './views/Login.vue';

export default {
  components: {
    Menubar, Login,
  },

  data () {
    return {
      uiReady: false,
      common: null,
      searchTerm: "",
      searchDisabled: false,
    }
  },

  computed: {
    menu () {
      const all = [
        {
          label: "Destek",
          items: [
            {label: "Destekçiler", icon: 'pi pi-users',
              to: {name: "crud", params: {model: 'Member'}}},
            //{label: 'Rezervasyon', icon: 'pi pi-shopping-cart', to: {name: "reservation"}},
            {label: 'Rezervasyon', icon: 'pi pi-shopping-cart',
              to: {name: "crud", params: {model: 'Order'}}},
            {label: "Günlük Anons", icon: "pi pi-megaphone", to: {name: "daily"}},
            {label: "AR Seçsin", icon: "pi pi-flag", to: {name: "ar-choose"}},
            {label: "Ödenmemiş", icon: 'pi pi-thumbs-down', to: {name: "unpaid"}},
            {separator: true},
            {label: "Özel Yayın - Form", icon: 'pi pi-phone', to: {name: 'call-form'}},
            {label: "Özel Yayın - Döküm", icon: 'pi pi-list', to: {name: 'call-report'}},
          ],
        },
        {
          label: "Tanımlar",
          items: [
            {label: "Yayın Akışı", icon: 'pi pi-calendar', to: {name: "schedule"}},
            {label: "Yeni Yayın Dönemi", icon: 'pi pi-calendar-plus', to: {name: "period"}},

            {label: "Programlar", icon: 'pi pi-box', to: {name: "crud", params: {model: 'Program'}}},
            {label: "Bankalar", icon: 'pi pi-building', to: {name: "crud", params: {model: 'Bank'}}},
            {label: "Pos BIN", icon: 'pi pi-credit-card', to: {name: "crud", params: {model: 'PosBin'}}},
            {label: "Yayın Dönemleri", icon: 'pi pi-folder-open', to: {name: "crud", params: {model: 'Period'}}},
            {label: "Ayarlar", icon: 'pi pi-cog', to: {name: "settings"}},
          ]
        },
        {
          label: "Raporlar",
          items: [
            {label: "Çoğalıyoruz", icon: 'pi pi-heart', to: {name: "grow"}},
            {label: "Fatura", icon: 'pi pi-book', to: {name: "invoice"}},
            {label: "Webden", icon: "pi pi-globe", to: {name: "from-web"}},
            {label: "Destek Tarihçesi", icon: "pi pi-history", to: {name: "support-history"}},
            {label: "Kesintisiz", icon: "pi pi-list", to: {name: "continuous"}},
            {label: "Yıllara Göre", icon: "pi pi-sort-alt-slash", to: {name: "yearly-aggregate"}},
            {label: "POS Kayıtları", icon: 'pi pi-money-bill', to: {name: "crud", params: {model: "Transaction"}}},
          ]
        }
      ];

      //callers can only see the admin menu +
      if (this.$admin.value.role === 'telefoncu') {
        return [
          {label: "Özel Yayın - Form", icon: 'pi pi-phone', to: {name: 'call-form'}}
        ];
      }

      return all;
    },

    adminMenuItems () {
      const items = [
        { label: "Şifre değiştir", icon: "pi pi-key", to: {name: "change-pass"} },
        { label: "Yöneticiler", icon: "pi pi-user-edit", to: {name: "super"} },
        { label: "Çıkış", icon: "pi pi-power-off", command: () => this.logout() },
      ];
      if (this.$admin.value.role !== 'süper') items.splice(1, 1);
      return items;
    }
  },

  methods: {
    async logout () {
      await this.$post("/admin/logout");
      this.$admin.value = null;
    },

    async loadCommon () {
      if (this.$route.name === 'logout') return;
      if (this.$admin.value && ['admin', 'süper'].includes(this.$admin.value.role)) {
        this.common = await this.$get("/admin/common");
      }
    },

    async search () {
      this.searchDisabled = true;
      const ret = await this.$get("/admin/search", {q: this.searchTerm});
      this.searchDisabled = false;
      console.log(ret.member);
      if (! ret.member)
        return this.$toast.add({severity: "warn", detail: "Bulunamadı", life: 1500});
      this.searchTerm = "";
      this.$router.push({name: "past-orders", params: {memberId: ret.member.id}});
    }
  },

  mounted () {
    document.addEventListener("keyup", e => {
      if (e.key === "F3" || (e.ctrlKey && e.key === "m")) {
        this.$refs["global-search"]?.$el.focus();
      }
    });
  },

  async created () {
    const ret = await this.$get("/admin");
    this.uiReady = true;
    if (ret.admin) this.$admin.value = ret.admin;
  },

  watch: {
    "$route" (n, old) {
      if (! old.name) return; //this happens on page refresh. name = undefined
      this.loadCommon();
    },

    "$admin.value" () {
      this.loadCommon();
    }
  }
}
</script>

<style scoped>
/* without z, menu goes below crud grid headers */
:deep(.p-menubar .p-submenu-list) {
  @apply z-10;
}
</style>
