<template>
  <form-item>
    <SplitButton label="Getir" severity="primary"
      class="w-full lg:w-auto" :model="realCommands"
      :button-props="{type: 'submit', loading: loading}"
     />
  </form-item>
</template>

<script>
export default {
  components: {},

  props: ["commands", "loading"],

  data () {
    return {
    }
  },

  computed: {
    realCommands () {
      const ret = [];
      ret.push({ label: 'Yazdır', icon: 'pi pi-print', command: () => {
        setTimeout(print, 300); //oterwise split button menu items left visible
      }});
      if (this.commands.xls)
        ret.push({ label: 'XLS', icon: 'pi pi-file-excel', command: () => this.commands.xls() });
      return ret;
    },
  },

  created () {

  }
}
</script>