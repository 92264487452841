<template>

  <form>
    <div class="grid md:grid-cols-3">
      <div>
        <form-row>
          <form-item label="Ad - Soyad">
            <InputText type="text" v-model="member.ad_soyad" required />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="E-posta">
            <InputText type="email" v-model="member.email" required />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Admin notu">
            <Textarea v-model="member.notes" cols="21" rows="3" />
          </form-item>
        </form-row>
      </div>

      <div>
        <form-row>
          <form-item label="Adres">
            <Textarea v-model="member.adres" cols="21" rows="2" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="İlçe">
            <InputText type="text" v-model="member.ilce" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Şehir">
            <InputText type="text" v-model="member.sehir" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Ülke">
            <InputText type="text" v-model="member.ulke" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Posta kodu">
            <InputText type="text" v-model="member.posta_kodu" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Telefon 1">
            <InputText type="text" v-model="member.tel1" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Telefon 2">
            <InputText type="text" v-model="member.tel2" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Telefon 3">
            <InputText type="text" v-model="member.tel3" />
          </form-item>
        </form-row>
      </div>

      <div>
        <form-row>
          <form-item label="Faturadaki isim">
            <InputText type="text" v-model="member.fatura_ad" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Fatura adresi">
            <InputText type="text" v-model="member.fatura_adres" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Vergi dairesi">
            <InputText type="text" v-model="member.vd" />
          </form-item>
        </form-row>

        <form-row>
          <form-item label="Vergi no">
            <InputText type="text" v-model="member.vno" />
          </form-item>
        </form-row>
      </div>

    </div>
  </form>
</template>

<script>
export default {
  emits: ["member"],

  data () {
    return {
      member: {
        id: null,
        ad_soyad: "",
        email: "",
        adres: "",
        ilce: "",
        sehir: "",
        ulke: "",
        posta_kodu: "",
        tel1: "",
        tel2: "",
        tel3: "",
        fatura_ad: "",
        fatura_adres: "",
        vd: "",
        vno: "",
        notes: "",
      }
    }
  },

  methods: {
    async save () {
      const ret = await this.$post("/member/save", this.member);
      if (ret.err) return this.$toast.add({severity: "error", summary: "Hata", detail: ret.err, life: 3000});
      this.$emit('member', ret.member);
    }
  }
}
</script>